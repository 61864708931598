/**
* main.css
*
* CSS common to every view of the website
*
* Index:
* - Global colors to be used within the views
* - Style used to create a unified theme for the website and override some Bootstrap classes
* - Style used for the temporary alert display
* - Style used for the page scrolling
* - Style used for the image loading and the directory/event modal
* - Style used for the admin dashboard
* - Style used for the admin form
* - Style used for the association and event detail view
*
* Author: See list of contributors <https://gitlab.com/sia-insa-lyon/portailva/-/graphs/master?ref_type=heads>
* License: AGPL-3.0-or-later
*/

:root {
    --pva-alert-indicator: rgb(166 29 54 / 40%);
    --pva-black: #333;
    --pva-blue: #007bff;
    --pva-dashboard-header: #ffc4ff;
    --pva-dashboard-header-active: #d05ce3;
    --pva-green: rgb(63 229 62);
    --pva-grey: #ccc;
    --pva-grey-dark: #606263;
    --pva-grey-light: #f8f8f8;
    --pva-grey-moderate-light: #ededed;
    --pva-grey-transparent: rgb(0 0 0 / 30%);
    --pva-grey-transparent-dark: rgb(0 0 0 / 50%);
    --pva-grey-transparent-light: rgb(0 0 0 / 8%);
    --pva-grey-transparent-moderate-light: rgb(0 0 0 / 12.5%);
    --pva-red: #e93c46;
    --pva-red-dark: #d22f38;
    --pva-white: #fff;
}

html {
    position: relative;
    min-height: 100%;
}

/* Fixed top navbar */
body {
    padding-top: 70px;
    margin-bottom: 40px;
}

.brand-colored {
    color: var(--pva-red);
}

body > .container {
    margin-bottom: 4em;
}

/* Navbar dropdown */
.dropdown-item-danger {
    color: var(--pva-red);
}

.dropdown-item-danger:focus,
.dropdown-item-danger:hover {
    color: var(--pva-red);
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item-danger.active,
.dropdown-item-danger:active {
    color: var(--pva-white);
    text-decoration: none;
    background-color: var(--pva-red-dark);
}

/* Responsive heading */
h1,
h2,
h3,
h4,
h5,
h6 {
    word-wrap: break-word;
    hyphens: auto;
}

/* Responsive card column */
@media (width >= 576px) {
    .card-columns {
        column-count: 1;
    }
}

@media (width >= 768px) {
    .card-columns {
        column-count: 2;
    }
}

@media (width >= 992px) {
    .card-columns {
        column-count: 3;
    }
}

/* Alert */
.bottom-0 {
    bottom: 0 !important;
}

.end-0 {
    right: 0 !important;
}

.icon-badge {
    position: relative;
}

.icon-badge-text {
    display: none;
}

.icon-badge:not(:hover) i {
    color: var(--pva-grey-transparent-dark);
}

.icon-badge:not(:hover) .icon-badge-text {
    color: var(--pva-grey-transparent-dark);
}

.icon-badge .badge {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
}

@media (width <= 992px) {
    .icon-badge {
        margin-left: -0.75rem;
    }

    .icon-badge-text {
        display: inline-block;
    }
}

.pulse-animation {
    animation: pulse 0.75s infinite;
}

.pulse-animation:hover {
    animation: none;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--pva-alert-indicator);
    }

    50% {
        box-shadow: 0 0 0 1.5px var(--pva-alert-indicator);
    }

    100% {
        box-shadow: 0 0 0 0 var(--pva-alert-indicator);
    }
}

/* Fixed footer */
footer {
    align-items: center;
    background-color: var(--pva-grey-light);
    bottom: 0;
    display: flex;
    font-size: 15px;
    height: min(4rem, 50px);
    left: 0;
    position: absolute;
    width: 100%;
    text-align: center;
}

@media (width <= 580px) {
    footer {
        display: block;
        height: 65px;
        text-align: center;
    }
}

footer a i {
    font-size: max(20px, 3vh);
}

footer div {
    margin: auto 0 auto auto;
}

footer ul {
    margin: 0;
    padding: 0;
}

footer p {
    margin: 0 0 0 10px;
}

footer li {
    display: inline;
}

footer li a {
    margin: 20px;
}

footer > .text-muted > .brand {
    color: var(--pva-black);
}

footer > .text-muted > .brand > a {
    color: var(--pva-black);
    text-decoration: underline;
}

footer > .text-muted > .brand > a:hover {
    color: var(--pva-red);
}

footer > .text-muted > .version > a {
    color: var(--pva-black);
    text-decoration: underline;
}

footer > .text-muted > .version > a:hover {
    color: var(--pva-red);
}

footer .brand-colored:hover {
    color: var(--pva-black);
}

blockquote .list-inline-item > a:hover {
    color: var(--pva-black);
}

/* Icons */
i.fa {
    padding-right: 5px;
}

i.fab {
    padding-right: 5px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.scroll-top {
    display: none;
    position: fixed;
    bottom: min(5rem, 65px);
    right: 20px;
    z-index: 100;
    height: 32px;
    width: 36px;
    background-color: var(--pva-grey-transparent);
    border-radius: 4px;
    text-align: left;
    transition: background-color 0.2s ease-in;
}

@media (width <= 580px) {
    .scroll-top {
        bottom: 85px;
    }
}

.scroll-top i {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 7px 0 0 12px;
    color: var(--pva-white);
}

.scroll-top:hover {
    cursor: pointer;
    background-color: var(--pva-red);
}

.tab-content {
    padding-top: 20px;
}

#highlights {
    min-height: 230px;
}

.has-error {
    color: var(--pva-red) !important;
}

.has-success {
    color: var(--pva-green) !important;
}

.list-group-item:hover {
    transition: background 0.4s ease;
    background-color: var(--pva-grey) !important;
    border: var(--pva-grey);
    border-radius: 5px;
    word-break: normal;
}

.card-home {
    border: none;
}

.figure {
    font-size: xx-large;
}

@keyframes animateSkeleton {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

.skeleton {
    background-color: var(--pva-grey-transparent-light);
    height: 40vh;
    animation: 1.5s ease-in-out infinite animateSkeleton;
}

.skeletonThumb {
    height: 75%;
}

.skeletonText {
    height: 0.8em;
    width: 70%;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    border-radius: 4px;
}

.skeletonShortText {
    height: 0.8em;
    width: 40%;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    border-radius: 4px;
}

.skeletonTitle {
    height: 1.2em;
    width: 50%;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    border-radius: 4px;
}

.skeletonBadge {
    height: 1em;
    width: 30%;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    padding: 0.25em 0.6em;
    border-radius: 10rem;
}

.skeleton > img {
    display: none;
}

.invalid-feedback {
    display: inline;
}

.dashboard-section {
    cursor: pointer;
}

.dashboard-section:hover {
    transition: background 0.4s ease;
    background-color: var(--pva-grey) !important;
}

.dashboard-card-round {
    border-radius: 0.25rem !important;
    border: 1px solid var(--pva-grey-transparent-moderate-light) !important;
}

.dashboard-card-round > .card-header {
    transition: background 0.4s ease;
    background-color: var(--pva-dashboard-header);
}

.dashboard-card-top-round {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    border: 1px solid var(--pva-grey-transparent-moderate-light) !important;
}

.dashboard-card-top-round > .card-header {
    transition: background 0.4s ease;
    background-color: var(--pva-dashboard-header-active);
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}

.dot-active {
    background-color: var(--pva-green);
}

.dot-inactive {
    background-color: var(--pva-red);
}
